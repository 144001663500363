import React from 'react';
import { Link } from 'gatsby';

import useResponsive from '../hooks/useResponsive';

import ParisVideo from '../static/video/paris.mp4';

import {
  Layout, Typography, Image, Separator,
} from '../components';

const Index = () => {
  const responsive = useResponsive();

  return (
    <Layout
      title="Chirurgien Orthopédiste de la main à Paris : Docteur Ghazanfari"
      description="Chirurgien orthopédiste à Paris, le Dr Ghazanfari est spécialisé dans la chirurgie des membres supérieurs (main, coude, epaule) et chirurgie du sport."
      headerStyle="transparent"
    >
      <div className="bg-background">
        <div className="relative w-full h-[900px]">
          {responsive.current > responsive.breakpoint.SM ? (
            <video className="h-[900px] w-full object-cover" autoPlay muted loop preload="auto">
              <source src={ParisVideo} type="video/mp4" />
            </video>
          ) : null}

          <div className="absolute inset-0 flex flex-col justify-center h-full px-4 space-y-4 text-center bg-black/20">
            <Typography className="flex flex-col uppercase tracking-[10px] space-y-4" variant="h1" color="white" weight="bold">
              <Typography variant="span" className="text-[25px] mdx:text-xl mdx:leading-xl xs:text-xl-r xs:leading-xl-r flex flex-col uppercase tracking-[10px]" size="none" color="white" weight="black">
                Docteur Amir
                {' '}
                <Typography variant="span" className="text-[25px] mdx:text-xl mdx:leading-xl xs:text-xl-r xs:leading-xl-r uppercase tracking-[10px]" size="none" color="white" weight="black">Ghazanfari</Typography>
              </Typography>
              <Typography className="text-[19px] mdx:text-lg mdx:leading-lg xs:text-lg-r xs:leading-lg-r flex flex-col" variant="span" size="none" color="white" weight="bold">
                Chirurgien orthopédiste
                {' '}
                <Typography className="text-[19px] mdx:text-lg mdx:leading-lg xs:text-lg-r xs:leading-lg-r" variant="span" size="none" color="white" weight="bold">du membre supérieur</Typography>
              </Typography>
            </Typography>
          </div>
        </div>

        <div className="max-w-screen-sm px-4 py-12 mx-auto mdx:max-w-screen-xlg mt-[-200px] relative">
          <div className="bg-white">
            <div className="relative flex flex-col">
              <div className="z-[10] absolute inset-[10px] md:inset-[20px] border border-opacity-[0.75] border-[#e0dadc]" />
              <div className="grid grid-cols-1 mdx:grid-cols-2">
                <div className="z-[15] flex flex-col px-6 py-12 mx-auto space-y-6 sm:px-12 lg:px-20">
                  <Typography variant="h2" color="black" size="lg" weight="bold">Présentation</Typography>
                  <Separator />
                  <Typography color="primary">
                    Le Dr Ghazanfari Amir est
                    {' '}
                    <Typography variant="span" color="primary" weight="semiBold">chirurgien orthopédiste du membre supérieur.</Typography>
                    {' '}
                    Situé à Paris, il s&apos;occupe exclusivement des
                    {' '}
                    <Typography variant="span" color="primary" weight="semiBold">pathologies de la main, du coude et de l&apos;épaule</Typography>
                    {' '}
                    de la population générale et des sportifs.
                  </Typography>
                  <Typography color="primary">
                    Il utilise les dernières technologies (arthroscopie, vidéo-chirurgie,
                    micro-chirurgie)
                    afin de rendre la chirurgie plus performante et moins invasive.
                  </Typography>
                  <Typography color="primary">
                    Il est membre des principales
                    {' '}
                    <Typography variant="span" color="primary" weight="semiBold">sociétés savantes de chirurgie orhopédique :</Typography>
                  </Typography>
                  <ul className="flex flex-col space-y-2">
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        Société Française de Chirurgie Orhopédique et de Traumatologie (
                        <a className="underline text-link hover:text-primary" href="https://www.sofcot.fr/" target="_blank" rel="noreferrer">SOFCOT</a>
                        )
                      </Typography>
                    </li>
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        Société Française de Chirurgie de la Main (
                        <a className="underline text-link hover:text-primary" href="https://www.sfcm.fr/" target="_blank" rel="noreferrer">GEM</a>
                        )
                      </Typography>
                    </li>
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        Société Française d&apos;Arthroscopie (SFA)
                      </Typography>
                    </li>
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        Collège Français des Chirurgiens Orthopédistes et Traumatologiques
                      </Typography>
                    </li>
                  </ul>
                </div>
                <Image className="h-[700px] mdx:h-[825px]" imageName="portrait-01.jpg" />
              </div>
              <div className="grid grid-cols-1 mdx:grid-cols-2">
                <Image className="order-2 mdx:order-0 h-[700px] mdx:h-[825px]" imageName="portrait-02.jpg" />
                <div className="z-[15] flex flex-col order-1 px-6 py-12 mx-auto space-y-6 mdx:order-2 sm:px-12 lg:px-20">
                  <Typography variant="h2" color="black" size="lg" weight="bold">Qualifications et parcours</Typography>
                  <Separator />
                  <Typography color="primary">
                    Après avoir fait ses études de médecine (externat)
                    à l&apos;Hôpital de la Pitié-Salpêtrirère (Université Pierre et Marie Curie,
                    Paris 6), le Dr Ghazanfari a été interne des hôpitaux de Paris (AP-HP)
                    pendant 5 ans. Il a ensuite été Chef de Clinique
                    à l&apos;Hôpital Saint-Antoine dans
                    le Service du Pr Foursounian durant deux années.
                    Afin de parfaire sa formation, le Dr Ghazanfari a réalisé de
                    nombreux Fellowships
                    en France et à l&apos;étranger :
                  </Typography>
                  <ul className="flex flex-col space-y-2">
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        <Typography variant="span" color="primary" weight="bold">Aspetar</Typography>
                        {' '}
                        : Centre agréé FIFA pour la prise en charge des sportifs
                        de haut niveau (Dr Landreau, Qatar)
                      </Typography>
                    </li>
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        <Typography variant="span" color="primary" weight="bold">Hand and Wrist Unit</Typography>
                        {' '}
                        : Centre de chirurgie de la main et du poignet (Dr Chick, Genève)
                      </Typography>
                    </li>
                    <li className="relative pl-5 before:top-[12px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                      <Typography color="primary">
                        <Typography variant="span" color="primary" weight="bold">Alps Surgery Institut</Typography>
                        {' '}
                        : Centre de chirurgie de l&apos;Épaule (Dr Lafosse, Annecy)
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative hidden mdx:block">
          <span className="absolute left-0 top-0 w-full bg-gradient-to-b from-background to-[#00010100] h-[200px] z-[1]" />
          <Image imageName="home-slide-2.jpg" className="h-[750px]" />
          <span className="absolute left-0 bottom-0 w-full bg-gradient-to-t from-background to-[#00010100] z-[1] h-[200px]" />
        </div>

        <div className="px-4 py-12 mdx:mt-[-150px] mdx:relative z-[100]">
          <div className="p-5 mx-auto bg-white max-w-screen-xlg">
            <div className="flex flex-col items-center px-4 sm:px-6 py-4 space-y-6 border border-opacity-[0.75] border-[#e0dadc]">
              <Typography variant="h2" color="black" size="lg" weight="bold">Diplômes</Typography>
              <Separator height="h-[0.2rem]" width="w-[100px]" />
              <div className="grid grid-cols-1 mdx:grid-cols-2 divide-y mdx:divide-y-0 mdx:divide-x divide-[#ececec]">
                <ul className="flex flex-col space-y-1 mdx:px-4">
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">Lauréat de la Faculté de médecine de Paris</Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Maitrise de Science (M1) de Biochimie métabolique
                      et régulation (Pr.Raisonnier)
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Maitrise de Science (M1) d&apos;anatomie, imagerie
                      et morphogenèse du Système nerveux (Dr Hasboun)
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Certificat de synthèse clinique et thérapeutique
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Formation IRCAD à Strasbourg : cours de base
                      et cours avancés d&apos;arthroscopie (poignet et épaule)
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Cours Européens des Lambeaux des Membres
                      (Pr Masquelet, École de Chirurgie à Paris)
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Diplôme Universitaire d&apos;Anatomie Clinique (Université Paris 5, Pr DELMAS)
                    </Typography>
                  </li>
                </ul>

                <ul className="flex flex-col space-y-1 mdx:px-4">
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">Diplôme Universitaire de Microchirurgie (Faculté de médecine Xavier Bichat, Université Paris 7, Pr Oberlin)</Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">Diplôme universitaire de Chirurgie de la Main (Faculté de médecine Xavier Bichat, Université Paris 7, Pr Oberlin)</Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Diplôme universitaire d&apos;Arthroscopie avec le
                      Dr Graveleau (Clinique du Sport, Paris 5)
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Diplôme d&apos;Études Spécialisées de Chirurgie Générale
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Diplôme d&apos;Études Spécialisées Complémentaires
                      de Chirurgie Orthopédique et de Traumatologie
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Thèse de doctorat en médecine (Pr Masquelet, Pr Doursounian)
                      : Mention très honorable, médaille d&apos;argent
                    </Typography>
                  </li>
                  <li className="relative py-2 pl-5 before:top-[20px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
                    <Typography color="primary">
                      Titulaire du Titre de Chirurgien de la Main délivré par
                      la Société Française de Chirurgie de la Main
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto bg-background-light">
          <div className="flex flex-col px-4 py-12 mx-auto space-y-12 text-center max-w-screen-xlg">
            <Typography className="uppercase tracking-[0.3em]" variant="h2" color="white" size="lg" weight="bold">Mes soins orthopédiques</Typography>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <Link to="/chirurgie-de-la-main" className="transition-all duration-300 ease-in-out bg-white shadow hover:shadow-lg">
                <Image className="min-h-[10rem] max-h-[18rem]" imageName="op-mains.jpg" />
                <div className="p-5">
                  <div className="flex flex-col items-center px-2 py-4 space-y-4 border border-opacity-[0.75] border-[#e0dadc]">
                    <Typography variant="h3" size="mdxl" color="primary" weight="bold">Chirurgie de la main</Typography>
                  </div>
                </div>
              </Link>
              <Link to="/chirurgie-du-coude" className="transition-all duration-300 ease-in-out bg-white shadow hover:shadow-lg">
                <Image className="min-h-[10rem] max-h-[18rem]" imageName="op-coude.jpg" />
                <div className="p-5">
                  <div className="flex flex-col items-center px-2 py-4 space-y-4 border border-opacity-[0.75] border-[#e0dadc]">
                    <Typography variant="h3" size="mdxl" color="primary" weight="bold">Chirurgie du coude</Typography>
                  </div>
                </div>
              </Link>
              <Link to="/chirurgie-de-lepaule" className="transition-all duration-300 ease-in-out bg-white shadow hover:shadow-lg">
                <Image className="min-h-[10rem] max-h-[18rem]" imageName="op-epaule.jpg" />
                <div className="p-5">
                  <div className="flex flex-col items-center px-2 py-4 space-y-4 border border-opacity-[0.75] border-[#e0dadc]">
                    <Typography variant="h3" size="mdxl" color="primary" weight="bold">Chirurgie de l&apos;épaule</Typography>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="mx-auto bg-background">
          <div className="flex flex-col px-4 py-16 mx-auto space-y-8 max-w-screen-xlg">
            <div className="flex flex-col space-y-3">
              <Typography color="white">
                Chirurgien Orthopédiste spécialisé en membre supérieur et en chirurgie du Sport à
                Paris, le docteur Amir Ghazanfari, prend en charge exclusivement les pathologies
                de la main, du poignet et de l&apos;épaule.
              </Typography>
              <Typography color="white">
                Son profil de praticien hyperspécialisé lui permet de proposer
                son expertise chirurgicale sur
                de nombreuses pathologies en utilisant les techniques chirurgicales
                de pointe (arthroscopie,
                microchirurgie), rendant la chirurgie peu invasive.
              </Typography>
              <Typography color="white">
                Le Docteur
                {' '}
                <Typography variant="span" color="white" weight="bold">Amir Ghazanfari</Typography>
                {' '}
                intervient à cette adresse:
              </Typography>
              <ul className="flex flex-col">
                <li className="relative py-1">
                  <Typography color="white">
                    <Typography variant="span" color="white" weight="bold">Clinique du Montgardé - SOS Main - Aubergenville</Typography>
                    {' '}
                    dans les Yvelines (78410)
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
