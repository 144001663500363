import { useState, useEffect } from 'react';

const breakpoint = {
  XSS: 1,
  XS: 2,
  XSM: 3,
  SM: 4,
  MD: 5,
  MDX: 6,
  LG: 7,
  XLG: 8,
  XL: 9,
  '2XL': 10,
  '3XL': 11,
};

const useResponsive = () => {
  const [responsiveObj, setResponsiveObj] = useState({
    width: undefined,
    height: undefined,
    breakpoint,
    current: undefined,
  });

  const getCurrentBreakpoint = (width) => {
    if (width < 480) return breakpoint.XSS;
    if (width >= 480 && width < 560) return breakpoint.XS;
    if (width >= 560 && width < 640) return breakpoint.XSM;
    if (width >= 640 && width < 768) return breakpoint.SM;
    if (width >= 768 && width < 960) return breakpoint.MD;
    if (width >= 960 && width < 1024) return breakpoint.MDX;
    if (width >= 1024 && width < 1140) return breakpoint.LG;
    if (width >= 1140 && width < 1280) return breakpoint.XL;
    if (width >= 1280 && width < 1536) return breakpoint['2XL'];
    if (width >= 1536 && width < 1680) return breakpoint['3XL'];
    return breakpoint['3XL'];
  };

  useEffect(() => {
    const handleResize = () => {
      setResponsiveObj({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint,
        current: getCurrentBreakpoint(window.innerWidth),
      });
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return responsiveObj;
};

export default useResponsive;
